let dolphinAttributes = function(user_country, region, locale, device_id, station_code, transporter_id, description,description1,
                                 scan_content, module, build_version, device_manufacture, device_modal, marketplace, network_type,
                                 os_version, route_code) {

    this.user_country = user_country;
    this.region = region;
    this.locale = locale;
    this.device_id = device_id;
    this.description = description;
    this.station_code = station_code;
    this.transporter_id = transporter_id;
    this.module = module;
    this.description1 = description1;
    this.scan_content = scan_content;
    this.build_version = build_version;
    this.device_manufacture = device_manufacture;
    this.device_modal = device_modal;
    this.marketplace = marketplace;
    this.network_type = network_type;
    this.os_version = os_version;
    this.route_code = route_code
};

let dolphinMetrics = function(duration, failure) {
    this.duration = duration;
    this.failure = failure;
};

let dolphinEvents = function (name, attributes, metrics, immediate) {
    this.name = name;
    this.attributes = attributes;
    this.metrics = metrics;
    this.immediate = immediate;
};

export class dolphinEventsBuilder {

    setName (name) {
        this.name = name;
        return this;
    }
    setAttributes (attributes) {
        this.attributes = attributes;
        return this;
    }
    setMetrics (metrics) {
        this.metrics = metrics;
        return this;
    }
    setImmediate (immediate) {
        this.immediate = immediate;
        return this;
    }
    build () {
        return new dolphinEvents(this.name, this.attributes, this.metrics, this.immediate);
    }
}

export class dolphinMetricsBuilder {

    setDuration (duration) {
        this.duration = duration;
        return this;
    }
    setFailures () {
        this.failure = 1;
        return this;
    }

    build () {
        return new dolphinMetrics(this.duration, this.failure);
    }
}

export class dolphinAttributesBuilder {

    setCountry (country) {
        this.user_country = country;
        return this;
    }
    setRegion (region) {
        this.region = region;
        return this;
    }
    setLocale (locale) {
        this.locale = locale;
        return this;
    }
    setStationCode (stationCode) {
        this.station_code = stationCode;
        return this;
    }
    setDeviceIMEI (deviceIMEI) {
        this.device_id = deviceIMEI;
        return this;
    }
    setTransporterId (userId) {
        this.transporter_id = userId;
        return this;
    }
    setDescription (description) {
        this.description = description;
        return this;
    }
    setDescription1 (description1) {
        this.description1 = description1;
        return this;
    }
    setScanContent (scan_content) {
        this.scan_content = scan_content;
        return this;
    }
    setModule (module) {
        this.module = module;
        return this;
    }
    setBuildVersion (build_version) {
        this.build_version = build_version;
        return this;
    }
    setDeviceManufacture (device_manufacture) {
        this.device_manufacture = device_manufacture;
        return this;
    }
    setDeviceModal (device_modal) {
        this.device_modal = device_modal;
        return this;
    }
    setMarketplace (marketplace) {
        this.marketplace = marketplace;
        return this;
    }
    setDeviceNetworkType (network_type) {
        this.network_type = network_type;
        return this;
    }
    setDeviceOSVersion (os_version) {
        this.os_version = os_version;
        return this;
    }
    setRouteCode(route_code) {
        this.route_code = route_code;
        return this;
    }
    build () {
        return new dolphinAttributes(this.user_country, this.region, this.locale, this.device_id,
            this.station_code, this.transporter_id, this.description,this.description1, this.scan_content,
            this.module, this.build_version, this.device_manufacture, this.device_modal, this.marketplace,
            this.network_type, this.os_version, this.route_code);
    }
}