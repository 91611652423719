import axios from "axios";
import {authInterceptorBuilder} from "./AuthInterceptor";

export default {

    poll: false,
    dolphinAPIClient: null,

    /**
     * Generates Axios based API client
     * @param baseUrl Base url of respective backend service
     * @param timeout Timeout in millis for the network call defaults
     * to 10 seconds
     * @returns {Promise<void>}
     */
    async generateDolphinAPIClient(baseUrl, timeout = 20000) {
        const dolphinAPIClient = axios.create({
            baseURL: baseUrl,
            timeout: timeout
        });

        const authInterceptor = authInterceptorBuilder(dolphinAPIClient);

        dolphinAPIClient.interceptors.request.use(authInterceptor.onBeforeRequest, authInterceptor.onRequestError);
        dolphinAPIClient.interceptors.response.use(authInterceptor.onResponseSuccess, authInterceptor.onResponseError);

        this.dolphinAPIClient = dolphinAPIClient;
    }
}