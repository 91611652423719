/*
 * This interceptor adds access token to all calls. It also manages auth info, specifically access token, refresh token and
 * expiration date and time. It also takes care of refreshing access token when it is about to expire.
 */
// See: https://code.amazon.com/packages/AWSNovaGeneralWebsite/blobs/mainline/--/src/services/interceptors/authInterceptor.ts

import AnalyticEventKeys from "../helper/AnalyticEventKeys";
import MobileAnalyticsHelper from "../helper/MobileAnalyticsHelper";
import PUMAMetricHelper from "../helper/PUMAMetricHelper";
import Logger from "../utils/Logger";
import BearerTokenUtils from "./BearerTokenUtils";
import Constants from "../constants/Constants";
import { v4 as uuidv4 } from 'uuid';
import MeshClient from "../client/MeshClient";
import NativeMeshInteractor from "./NativeMeshInteractor";
import {getPerformanceData} from "../helper/PerformanceMonitoringHelper";
import DataHandler from "../handler/DataHandler";
let dolphinRequestId = "";

// Export Interceptors
export const authInterceptorBuilder = (clientAxiosInstance) => {
    return {
        onBeforeRequest,
        onRequestError,
        onResponseSuccess,
        onResponseError: onResponseErrorBuilder(clientAxiosInstance),
    };
};

// Interceptors
const onBeforeRequest = async (config) => {
    dolphinRequestId = uuidv4();
    checkInternetConnectivity();
    try {
        const logConfig = (({ method, url, headers, data, params}) => ({ method, url, headers, data, params}))(config);
        Logger.log.warn("Auth Interceptor Request", applyDolphinRequestId(logConfig));
        //Fallback case - Prevent adding access token in the header and adding customer id in request params if customerId is available
        if (DataHandler.getUserCustomerId()) {
            appendCustomerIdInRequest(config);
        } else {
            await applyAccessToken(config);
        }
        return config;
    } catch (error) {
        Logger.log.error("Auth Interceptor Before Request Error", error, applyDolphinRequestId());
        throw error;
    }
};

const onRequestError = (error) => {
    Logger.log.error("Auth Interceptor Request Error", error, applyDolphinRequestId());
    throw error;
};

const onResponseSuccess = async (response) => {
    const {config, ...logResponse} = response;
    const logResponseWithRequestId = applyDolphinRequestId(logResponse);
    const logResponseWithRequestIdAndPerformanceData = applyPerformanceData(logResponseWithRequestId);
    Logger.log.warn("Auth Interceptor Response Success", logResponseWithRequestIdAndPerformanceData);

    return response;
};


/**
 * Send LOGOUT_USER Mesh event to native app in case Associates (logged-in via fallback-login)
 * are operating after it has been disabled.
 * @param error Error received from sent request.
 */
const fallbackLoginDisabledErrorChecker = (error) => {
    if(error.response) {
        // if FALLBACK_LOGIN_DISABLED_ERROR is thrown, force-logout the user.
        if(error.response.data.message === Constants.ErrorCode.FALLBACK_LOGIN_DISABLED_ERROR) {
            NativeMeshInteractor.logoutUser();
        }
    }
}

const onResponseErrorBuilder = (axiosInstance) => async (error) => {
    const logResponseWithRequestId = applyDolphinRequestId();
    const logResponseWithRequestIdAndPerformanceData = applyPerformanceData(logResponseWithRequestId);

    Logger.log.error("Auth Interceptor Response Error", error, logResponseWithRequestIdAndPerformanceData);
    fallbackLoginDisabledErrorChecker(error);
    throw error;
};

async function applyAccessToken(config) {
    let accessToken = "";
    let startTime = Date.now();
    try {
        accessToken = BearerTokenUtils.accessToken;
        let accessTokenRetryCount = 2;
        while (!accessToken && accessTokenRetryCount--) {
            if (MeshClient.webSocketConnection.readyState === WebSocket.OPEN) {
                await BearerTokenUtils.setAccessToken();
            } else {
                Logger.log.warn('Attempting WS Connection from applyAccessToken');
                await MeshClient.connectWebSocket();
            }
            accessToken = BearerTokenUtils.accessToken;
        }
        if(!accessToken) {
            Logger.log.error('MAP Token Failure', e, applyDolphinRequestId());
            PUMAMetricHelper.publishLatencyToDolphinCSM(startTime, "AccessTokenRequest");
            MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.ACCESS_TOKEN_EXCHANGE, startTime, true);
        }
    } catch (e) {
        Logger.log.error('Exception in MAP Token Failure', e, applyDolphinRequestId());
        PUMAMetricHelper.publishLatencyToDolphinCSM(startTime, "AccessTokenRequest");
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.ACCESS_TOKEN_EXCHANGE, startTime, true);
    }
    // Append access token in the header
    if (accessToken) {
        config.headers["x-amz-access-token"] = accessToken;
    }
    return config;
}



const applyDolphinRequestId = (object = {}) => {
    return {...object, dolphinClientRequestId: dolphinRequestId};
};

const checkInternetConnectivity = () => {
    if (!navigator.onLine) {
        throw new Error(Constants.ErrorCode.DEVICE_OFFLINE);
    }
};

const applyPerformanceData = (logResponse) => {
    const performanceData = getPerformanceData(getResponseUrl(logResponse));
    return {...logResponse, performanceData: performanceData}
};

const getResponseUrl = (logResponse) => {
    if (logResponse?.request) {
        return logResponse.request.responseURL;
    }
};

const appendCustomerIdInRequest = (config) => {
    if (config.method === Constants.HTTPRequestMethods.GET_METHOD) {
        if (config.params) {
            config.params.customerId = DataHandler.getUserCustomerId();
        } else {
            config.params = {customerId: DataHandler.getUserCustomerId()}
        }
    } else if (config.method === Constants.HTTPRequestMethods.POST_METHOD) {
        if (config.data) {
            config.data.customerId = DataHandler.getUserCustomerId();
        } else {
            config.data = {customerId: DataHandler.getUserCustomerId()};
        }
    }
    return config;
};
