export default {
    US_EAST_1: {
        COGNITO_IDENTITY_POOL_ID: 'us-east-1:6b7aa5e1-583d-4a91-8d21-c2d3a337ade0',
        PINPOINT_APP_ID: '5e0cafc38eb2450da61e6ffadf092462',
        PINPOINT_REGION: 'us-east-1',
    },
    US_WEST_2: {
        COGNITO_IDENTITY_POOL_ID: 'us-west-2:2a51dd31-c80d-4c4a-aca3-eea45da3eaf3',
        PINPOINT_APP_ID: 'bfd5efce28444d549a83fd92e98b8a79',
        PINPOINT_REGION: 'us-west-2',
    },
    EU_WEST_1: {
        COGNITO_IDENTITY_POOL_ID: 'eu-west-1:e3537bfb-117a-4792-b738-02b57ed8b433',
        PINPOINT_APP_ID: '63f5664869fb4e9483e6b954d858add6',
        PINPOINT_REGION: 'eu-west-1',
    }
}