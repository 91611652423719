/**
 * Wraps input promise with another promise which will be resolved after 'timeout' milliseconds.
 * Returns a new promise which either resolves when input promise is completed or if max time has elapsed.
 * Note: This method does not handle rejects or timeouts ie it throws exceptions.
 * @param inputPromise
 * @param timeout
 * @return {Promise<void>}
 */
export function wrapWithTimeout(inputPromise, timeout) {
    const timeoutError = "TimeoutError";
    let timer;
    return Promise.race([inputPromise,
        new Promise((_r, rej) => timer = setTimeout(rej, timeout, timeoutError))])
        .finally(() => clearTimeout(timer));
}