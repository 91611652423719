import Logger from "../utils/Logger";

const XML_HTTP_REQUEST = "xmlhttprequest";
const FETCH_REQUEST = "fetch";
const DOLPHIN_HTTPS_DOMAIN = "https://dolphin";

export const getPerformanceData = (url) => {

    let performanceData = performance
        .getEntries()
        .filter((entry) => {
            return entry.initiatorType === XML_HTTP_REQUEST && entry.name === url
        });
    //Clear performance entries
    performance.clearResourceTimings();
    //return data for given url
    if (performanceData.length) {
        return performanceData[0];
    }
};

export default {
    performancePoller: null,
    intervalTimeInMS: 10 * 1000,
    latencyMonitorTimeInMS: 1000,
    startMonitoring(latencyThresholdInMS = this.latencyMonitorTimeInMS) {
        this.latencyMonitorTimeInMS = latencyThresholdInMS;
        this.performancePoller = setInterval(() => {
            //Fetch performance entries and filter them for fetch calls
            let performanceData = performance
                .getEntries()
                .filter((entry) => {
                    return ((entry.initiatorType === FETCH_REQUEST || entry.initiatorType === XML_HTTP_REQUEST) &&
                        entry.duration > this.latencyMonitorTimeInMS && !entry.name.startsWith(DOLPHIN_HTTPS_DOMAIN))
                });
            //Clear performance entries
            performance.clearResourceTimings();
            //Publish data to cloudwatch
            if (performanceData.length) {
                Logger.log.warn(performanceData);
            }
        }, this.intervalTimeInMS);
    },
    stopMonitoring() {
        clearInterval(this.performancePoller);
    }
};